import React, { useState, useEffect } from "react";
import InputMask from 'react-input-mask';
import close from "../imgs/icons/close.svg";

import bedB from '../imgs/icons/bedB.svg';
import Select from 'react-select';
import strutturaNode from 'node/struttura/struttura.js';
import stanzeNode from "node/stanze/stanze";
import delet from '../imgs/icons/delete.svg';
import modifica from '../imgs/icons/modifica.svg';
import confirmW from '../imgs/icons/confirmW.svg';
import magazzinoNode from "node/magazzino/magazzino";
import save from '../imgs/icons/save.svg';
import addW from '../imgs/icons/addW.svg';
import carica from '../imgs/icons/carica.svg';
import profiliNode from "node/profili/profili.js";
import allarmiNode from "node/allarmi/allarmi.js";

import lang from "../lang.json";


import Blebeacon from "./Blebeacon";
import Switch from "react-switch";


const langSet = lang[localStorage.getItem("lang")].scheda_stanza;
const sort = "id";
const order = "asc";
const SchedaStanza = (props) => {
    const [mod, setMod] = useState(0);
    const [status, setStatus] = useState(0);
    const [stanza, setStanza] = useState(props.stanza);
    const [letti, setLetti] = useState([]);
    const [sensori, setSensori] = useState([]);
    const [sensoriSel, setSensoriSel] = useState([]);
    const [sensoriRes, setSensoriRes] = useState([]);
    const [sensoriDel, setSensoriDel] = useState([]);
    const [tipoSensori, setTipoSensori] = useState([]);
    const [sensoriMag, setSensoriMag] = useState([]);
    const [allarmiAssenzastanzaMod, setAllarmiAssenzastanzaMod] = useState([]);

    const [allarmiassenzastanza, setallarmiassenzastanza] = useState([]);

    const [allarmiAssenzastanzaDel, setAllarmiassenzastanzadelDel] = useState([]);
    const accountData = JSON.parse(localStorage.getItem('account'));
    const [operation, setOperation] = useState();
    const [profili, setProfili] = useState([]);
    const [descrizione, setDescrizione] = useState("");
    const [alarms, setAlarms] = useState([]);
    const [idProfilo, setIdProfilo] = useState();
    const [allarmiDel, setAllarmiDel] = useState([]);
    const [allarmiSel, setAllarmiSel] = useState([]);
    const [allarmiCaduta, setAllarmiCaduta] = useState([]);
    const [allarmiCadutaMod, setAllarmiCadutaMod] = useState([]);
    const [allarmiCadutaDel, setAllarmiCadutaDel] = useState([]);



    // const [macAddress, setMacAddress] = React.useState([]);
    // const [macAddresses, setMacAddresses] = useState([]);

    // const handleMacAddressChange = (event) => {
    //     setMacAddress(event.target.value);
    // };


    // const [macAddresses, setMacAddresses] = useState([]);


    // const [macAddress, setMacAddress] = useState([]);



    const [searchTerm, setSearchTerm] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    const filteredOptions = sensoriMag.filter((sensoremag) => { // filtra i sensori che hanno lo stesso tipo di quelli già selezionati in sensoriSel
        return !sensoriSel.some((sensore) => sensore.tipo === sensoremag.tipo);


        })
      ? sensoriMag.filter((sensoremag) => { // filtra i sensori che hanno lo stesso tipo di quelli già selezionati in sensoriSel
        return !sensoriSel.some((sensore) => sensore.tipo === sensoremag.tipo);

        
        }).filter((sensore) => {
            console.log(sensore);
    
            const sensoreText = `${sensore.nome} - ${sensore.tipo}`;
            return sensoreText.toLowerCase().includes(searchTerm.toLowerCase());
          })
          .map((sensore) => ({
            value: sensore.id,
            label: `${sensore.nome} - ${sensore.tipo}`,
          }))
      : [];
    


    const defSelettore = "selettore selettoreFlex selettoreMiddle selettoreBorder";
    const selSelettore = "selettore selettoreFlex selettoreMiddle selettorePurple selettoreShadow";
    const defContinua = "selettore selettoreFlex selettoreBlue";
    const selettoreAllarmiassenzastanza = ['Nessun allarme', 'Allarme disabilitato', 'Allarme immediato', 'Allarme ritardato'];
    const selettoreAllarmiLetto = ['Nessun allarme', 'Allarme disabilitato', 'Allarme immediato', 'Allarme ritardato'];


        async function allarmiCadutaSwitch(id, mode, stato) {    //funzione per aggiungere, modificare o eliminare un allarme caduta
        try {
            props.loading(true);
            switch (mode) {
                case 'add': //? ---------- AGGIUNGI ALLARME CADUTA
                    setAllarmiCaduta([...allarmiCaduta, { descrizione: '', stato: '0', inizio: '', fine: '' }]);
                    break;
                case 'edit':        //? ---------- MODIFICA ALLARME CADUTA

                    let indexEdit = allarmiCadutaMod.findIndex((x) => x.id === id);
                    if (indexEdit === -1) {
                        setAllarmiCadutaMod([...allarmiCadutaMod, { id: id, stato: stato }]);
                    } else {
                        allarmiCadutaMod[indexEdit].stato = stato;
                        setAllarmiCadutaMod([...allarmiCadutaMod]);
                    }
                    break;
                case 'del': //? ---------- ELIMINA ALLARME CADUTA
                    let indexAllarme = allarmiCaduta.findIndex((x) => x.id === id); //trova l'indice dell'allarme da eliminare

                    if (indexAllarme !== -1) {  //se l'allarme è presente in allarmiCaduta
                        setAllarmiCadutaDel([...allarmiCadutaDel, allarmiCaduta[indexAllarme].id]);
                        allarmiCaduta.splice(indexAllarme, 1);
                        setAllarmiCaduta([...allarmiCaduta]);
                    }
                    break;
                default:
                    break;
            }
            props.loading(false);
            return;
        } catch (e) {
            console.log(e);
            return;
        }
    }

    async function getAllarmiCaduta() { //funzione per recuperare gli allarmi caduta
        props.loading(true);
        let id_sensore = sensori.filter((item) => item.tipo === "Sensore caduta")[0].id_sensore; //trova l'id del sensore caduta
        await allarmiNode.getAllarmiCaduta(accountData.token, id_sensore).then((res) => {
            setAllarmiCaduta(res.data);
            console.log(res.data)
        }).finally(() => {
            props.loading(false);
        });
    }

    async function createLetti() {
        setLetti([]);
        for (let i = 0; i < props.stanza.num_letti - 1; i++) {
            if (props.stanza.id_residenti.length < i) {
                setLetti(current => [...current, { id: i, stato: 'Occupato', residente: props.stanza.id_residenti[i] }]);
            } else {
                setLetti(current => [...current, { id: i, stato: 'Libero' }]);
            }
        }
        setStatus(1);
    }

    function openProfiloPopup(operation) {
        getProfili();
        setOperation(operation);
        let profPop = document.getElementById("ProfiloPopup").style.display;
        if (profPop === "none") {
            document.getElementById("ProfiloPopup").style.display = "flex";
        } else {
            document.getElementById("ProfiloPopup").style.display = "none";
        }
    }
    async function getallarmiassenzastanza() {
        props.loading(true);
        let id_sensore = sensori.filter((item) => item.tipo === "Sensore presenza stanza")[0].id_sensore;
        console.log(id_sensore)
        allarmiNode.getAllarmiOccupazioneStanza(accountData.token, id_sensore).then((res) => {
            setallarmiassenzastanza(res.data);
            console.log(res.data);
        }).finally(() => {
            props.loading(false);
        });
    }
    function openProfiloPopup(operation) {
        getProfili();
        setOperation(operation);
        let profPop = document.getElementById("ProfiloPopup").style.display;
        if (profPop === "none") {
            document.getElementById("ProfiloPopup").style.display = "flex";
        } else {
            document.getElementById("ProfiloPopup").style.display = "none";
        }
    }
    
    async function getProfili() {
        props.loading(true);
        let tipo = status === 4 ? "Sensore presenza stanza" :  null;
        await profiliNode.getProfili(accountData.token, tipo).then((res) => {
            setProfili(res.data);
        }).finally(() => {
            props.loading(false);
        });
    }

    useEffect(() => {
        getProfili();
    }, [status >= 3]);

    async function creaProfilo() {
        props.loading(true);
        let tipo = status === 3 ? "Sensore geofencing" : status === 4 ? "Sensore letto" : status === 5 ? "Sensore caduta" : null;
        await profiliNode.creaProfilo(accountData.token, descrizione, tipo, alarms).finally(() => {
            getProfili();
            openProfiloPopup();
            props.loading(false);
        });
    }

    async function loadProfilo() {
        props.loading(true);
        let tipo = status === 3 ? "Sensore geofencing" : status === 4 ? "Sensore letto" : status === 5 ? "Sensore caduta" : null;
        await allarmiNode.caricaProfilo(accountData.token, idProfilo, sensoriRes.filter((item) => item.tipo === tipo)[0].id_sensore).then((res) => {
            console.log(res)
            if (status === 5) { getallarmiassenzastanza(); console.log("5") }
        }
        ).finally(() => {
            openProfiloPopup();
            props.loading(false);
        });
    }

    const profilopopup = (
        <div id="ProfiloPopup" className="popupContent merda" style={{ display: "none", position: "absolute", top: "45%", left: "45%", transform: "translate(-50%, -50%)", }}>
            <div id="PopupBody" style={{ width: "30em" }}>
                <div style={{ padding: "1.5em", display: operation !== "salva" ? "none" : "" }}>
                    <label>Nome profilo da salvare</label>
                    <input
                        type="text"
                        placeholder={langSet.inserisciNome}
                        onChange={(e) => setDescrizione(e.target.value)}
                        style={{ width: "90%", margin: "1em" }}
                    />
                </div>
                <div style={{ padding: "1.5em", display: operation !== "carica" ? "none" : "" }}>
                    <label>Scegli profilo da caricare</label>
                    <select defaultValue="0" style={{ width: "99%", margin: "1em", padding: "0px" }} onChange={(e) => setIdProfilo(e.target.value)}>
                        <option value="0" disabled>Seleziona un profilo</option>
                        {profili.map((profilo, i) => {
                            return (<option key={i} value={profilo.id}>{profilo.descrizione}</option>);
                        })}
                    </select>
                </div>
                <div id="PopupFooter">
                    <div className="selettore selettoreFlex selettoreViolet" onClick={() => openProfiloPopup()}>
                        <p>{langSet.annulla}</p>
                        <img src={close} alt="close" />
                    </div>
                    <div className="selettore selettoreFlex selettoreBlue" onClick={() => { operation === "salva" ? creaProfilo() : loadProfilo() }}>
                        <p>{langSet.salva}</p>
                        <img src={confirmW} alt="confirm" />
                    </div>
                </div>
            </div>
        </div>
    );
    async function salvaModifiche(what) {
        try {
            let response; 
            switch (what) {
                case "deleteAllarmi":
                    if (allarmiDel.length > 0) {
                        for (let i = 0; i < allarmiSel.length; i++) {
                            if (allarmiDel[i] !== undefined) {
                                response = await allarmiNode.deleteAllarme(accountData.token, allarmiDel[i]);
                                if (!response.result) throw response.error;
                            }
                        }
                    }
                    break;
                case 'sensori':
                    if (sensoriDel.length > 0) {
                        
                        for (let i = 0; i < sensoriDel.length; i++) {
                            await stanzeNode.removeSensor(accountData.token, props.stanza.id, sensoriDel[i].id_sensore);
                        }
                    }
                    if (sensoriSel.length > 0) {
                        console.log(sensoriSel)
                        for (let i = 0; i < sensoriSel.length; i++) {
                            await stanzeNode.addSensor(accountData.token, props.stanza.id, sensoriSel[i].id);
                        }
                    }
                    break;
                 
                case 'allarmipresenzaStanza':
                    //TODO/* -------------------- FUNZIONE PER ELIMINAZIONE ALLARMI LETTO -------------------- */
                    for (let allarme_id of allarmiAssenzastanzaDel) {
                        if (allarme_id === undefined) continue;
                        if (allarme_id !== undefined) response = await allarmiNode.deleteAllarme(accountData.token, allarme_id);
                    }

                    //?/* -------------------- RECUPERO ID SENSORE LETTO -------------------- */

                    var id_sensore = sensori.filter((item) => item.tipo === "Sensore presenza stanza")[0].id_sensore;
                    console.log(sensori.filter((item) => item.tipo === "Sensore presenza stanza")[0].id_sensore)

                    //?/* -------------------- FUNZIONE PER AGGIUNTA ALLARMI LETTO -------------------- */
                    var nuoviAllarmi = [];

                    for (let allarme of allarmiassenzastanza) {
                        if (allarme.id || allarme.stato === 'Nessun allarme') continue;

                        allarme.id_sensore = id_sensore;
                        allarme.giorni = [0, 1, 2, 3, 4, 5, 6];
                        allarme.id_piani = [stanza.percorso.split('|')[0].split(' ')[0]];
                        allarme.tipo = 'allarme';
                        allarme.lingua = 'it';

                        switch (allarme.stato) {
                            case 'Allarme disabilitato':
                                allarme.valore = { presenceDetected: false };
                                allarme.abilitato = false;
                                allarme.messaggio = 'la stanza è vuota';
                                allarme.ritardo = false;
                                break;
                            case 'Allarme immediato':
                                allarme.valore = { presenceDetected: false };
                                allarme.abilitato = true;
                                allarme.messaggio = 'la stanza è vuota';
                                allarme.ritardo = false;
                                break;
                            case 'Allarme ritardato':
                                allarme.valore = { presenceDetected: false };
                                allarme.abilitato = true;
                                allarme.messaggio = 'la stanza è vuota';
                                allarme.ritardo = true;
                                break;
                            default:
                                break;
                        }

                        nuoviAllarmi.push(allarme);
                        console.log(allarme)
                    }
                    getallarmiassenzastanza();
                    
                    if (nuoviAllarmi.length > 0) response = await allarmiNode.addAllarme(accountData.token, nuoviAllarmi);
                    //?/* -------------------- FUNZIONE PER MODIFICA ALLARMI LETTO -------------------- */
                    for (let allarmeMod of allarmiAssenzastanzaMod) {
                        response = await allarmiNode.patchStatoAllarme(accountData.token, allarmeMod.id, allarmeMod.stato);
                    }

                    await getallarmiassenzastanza();
                    break;
    
                    break;
                case 'sensori':
                    break;
                    case 'allarmiCaduta':
                        //TODO/* -------------------- FUNZIONE PER ELIMINAZIONE ALLARMI LETTO -------------------- */
                        for (let allarme_id of allarmiCadutaDel) {
                            if (allarme_id === undefined) continue;
                            if (allarme_id !== undefined) response = await allarmiNode.deleteAllarme(accountData.token, allarme_id);
                        }
    
                        //?/* -------------------- RECUPERO ID SENSORE LETTO -------------------- */
    
                        var id_sensore = sensori.filter((item) => item.tipo === "Sensore caduta")[0].id_sensore;
    
                        //?/* -------------------- FUNZIONE PER AGGIUNTA ALLARMI LETTO -------------------- */
                        var nuoviAllarmi = [];
    
                        for (let allarme of allarmiCaduta) {
                            if (allarme.id || allarme.stato === 'Nessun allarme') continue;
    
                            allarme.id_sensore = id_sensore;
                            allarme.giorni = [0, 1, 2, 3, 4, 5, 6];
                            allarme.id_piani = [props.stanza.percorso.split('|')[0].split(' ')[0]];
                            allarme.tipo = 'allarme';
                            allarme.lingua = 'it';
    
                            switch (allarme.stato) {
                                case 'Allarme disabilitato':
                                    allarme.valore = { status: 'fall_detected' };
                                    allarme.abilitato = false;
                                    allarme.messaggio = 'Il paziente è caduto';
                                    allarme.ritardo = false;
                                    break;
                                case 'Allarme immediato':
                                    allarme.valore = { status: 'fall_detected' };
                                    allarme.abilitato = true;
                                    allarme.messaggio = 'Il paziente è caduto';
                                    allarme.ritardo = false;
                                    break;
                                case 'Allarme ritardato':
                                    allarme.valore = { status: 'fall_detected' };
                                    allarme.abilitato = true;
                                    allarme.messaggio = 'Il paziente è caduto';
                                    allarme.ritardo = true;
                                    break;
                                default:
                                    break;
                            }
    
                            nuoviAllarmi.push(allarme);
                        }
                        
                        if (nuoviAllarmi.length > 0) response = await allarmiNode.addAllarme(accountData.token, nuoviAllarmi);
    
                        //?/* -------------------- FUNZIONE PER MODIFICA ALLARMI LETTO -------------------- */
                        for (let allarmeMod of allarmiCadutaMod) {
                            response = await allarmiNode.patchStatoAllarme(accountData.token, allarmeMod.id, allarmeMod.stato);
                        }
    
                        await getAllarmiCaduta();
                        break;
                case 'letti':
                    break;
                default:
                    break;
            }


            setSensoriSel([]);
            setSensoriDel([]);
            setAllarmiSel([]);
            setAllarmiDel([]);

            setMod(0);
            props.loading(false);

            
        } catch (e) {
            console.log(e);
        }                
    }

    function Sens(azione, id) {
        //trova l'id del sensore in sensoriMa
        //! MODIFICATO DA SIMO, NON ELIMINARE IN MERGE GRAZIE
        if (azione === 'add') {
            setSensoriSel([...sensoriSel, sensoriMag.filter((item) => item.id === id)[0]]);
            setSensori([...sensori, sensoriMag.filter((item) => item.id === id)[0]]);
            setTipoSensori([...tipoSensori, sensoriMag.filter((item) => item.id === id)[0].tipo]);
        } else {
            let index;
            index = sensori.findIndex((x) => x.id_sensore === id);
            setSensoriDel([...sensoriDel, index !== -1 ? sensori.filter((item) => item.id_sensore === id)[0] : sensori.filter((item) => item.id === id)[0]]);
            console.log(sensoriDel)
            console.log(index)
            let indexTipo = tipoSensori.indexOf(sensori[index].tipo);
            tipoSensori.splice(indexTipo, 1);
            setTipoSensori([...tipoSensori]);

            sensori.splice(index, 1);
            setSensori([...sensori]);
            for (let sensoresel in sensoriSel) {
                if (sensoresel.id_sensore === id) {
                    index = sensoriSel.findIndex((x) => x.id_sensore === id);
                    sensoriSel.splice(index, 1);
                    setSensoriSel([...sensoriSel]);
                }
            }
        }
    }
    async function GetStanza(token, id) {  //!esempio controllo scheda in scheda
        props.loading(true);
        let piano = props.stanza.percorso.split(' | ')[0];
        let ala = props.stanza.percorso.split(' | ')[1];
        let corridoio = props.stanza.percorso.split(' | ')[2];
        await strutturaNode.getStrutturaAll(token).then((res) => {
            if(!res.result) throw res.error;
            res.data.map((p) => {
                if (piano === p.id)
                    p[piano].map((a) => {
                        if (ala === a.id)
                            a[ala].map((c) => {
                                if (corridoio === c.id)
                                    c[corridoio].map((s) => {
                                        if (id === s.id)
                                            setStanza(s);
                                        setStanza(current => ({ ...current, percorso: props.stanza.percorso }));
                                        //props.loading(false);
                                    })
                            })
                    })
            });
        }).catch((e) => props.SchedaInScheda('alarm', e.toString(), 'ritorna_stanza', props.stanza)).finally(() => props.loading(false));
    }

    async function getSensori() {
        props.loading(true);
        //prendi tutti i sensori dal magazzino struttura 
        await magazzinoNode.getMagazzinoStruttura(accountData.token, sort, order).then((res) => {
            if(!res.result) throw res.error;
            setSensoriMag(res.data);
        }).catch((e) => props.SchedaInScheda('scheda_stanza', e.toString(), 'sensori', sensori)).finally(() => props.loading(false));
    }

    async function GetSensoriStanza() {
        props.loading(true);
        // console.log("ciao mamma 2");
        // console.log(stanza.id)
        await stanzeNode.getSensoriStanza(accountData.token, props.stanza.id).then((res) => {
            if(!res.result) throw res.error;
            setSensori(res.data);
            console.log(res.data);
            let temp = [];
            for (let i = 0; i < res.data.length; i++) {
                temp.push(res.data[i].tipo);
            }
            setTipoSensori(temp);
        }).catch((e) => props.SchedaInScheda('scheda_stanza', e.toString(), 'sensori_stanza', sensori)).finally(() => props.loading(false));
    }

    async function allarmiassenzastanzaSwitch(id, mode, stato) {
        try {
            props.loading(true);
            switch (mode) {
                case 'add':
                    setallarmiassenzastanza([...allarmiassenzastanza, { descrizione: '', stato: '0', inizio: '', fine: '' }]);
                    break;
                case 'edit':

                    let indexEdit = allarmiAssenzastanzaMod.findIndex((x) => x.id === id);
                    if (indexEdit === -1) {
                        setAllarmiAssenzastanzaMod([...allarmiAssenzastanzaMod, { id: id, stato: stato }]);
                    } else {
                        allarmiAssenzastanzaMod[indexEdit].stato = stato;
                        setAllarmiAssenzastanzaMod([...allarmiAssenzastanzaMod]);
                    }
                    break;
                case 'del':
                    let indexAllarme = allarmiassenzastanza.findIndex((x) => x.id === id);

                    if (indexAllarme !== -1) {
                        setAllarmiassenzastanzadelDel([...allarmiAssenzastanzaDel, allarmiassenzastanza[indexAllarme].id]);
                        allarmiassenzastanza.splice(indexAllarme, 1);
                        setallarmiassenzastanza([...allarmiassenzastanza]);
                    }
                    break;
                default:
                    break;
            }
            props.loading(false);
            return;
        } catch (e) {
            console.log(e);
            return;
        }
    }


    useEffect(() => {
        getSensori()
        GetSensoriStanza()
        setMod(0);
        setStatus(0);
        setStanza(props.stanza);
        console.log(props.stanza)
        setLetti([]);

        if ( props.stanza.letti[0] === undefined || props.stanza.letti[0].stato === undefined) GetStanza(accountData.token, props.stanza.id); //!esempio controllo scheda in scheda
    }, [props]);


    // console.log(GetSensoriStanza());

    // const saveMacAddress = async () => {
    //     console.log('test');
    //     try {
    //         const response = await fetch('http://localhost:3000/save-mac-address', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify({ macAddress }),
    //         });
    //         const data = await response.json();
    //         console.log(data);
    //     } catch (error) {
    //         console.error('Error saving MAC address:', error);
    //     }
    // };




    return (
        <>
            <div id="Header" className="headerPopup" style={{ justifyContent: "start", overflowY: "auto" }}>
                <div className={status === 0 ? selSelettore : defSelettore} onClick={() => setStatus(0)}>
                    <p>{langSet.dettagli}</p>
                </div>
                <div className={status === 1 ? selSelettore : defSelettore}style={props.stanza.num_letti === "0" || props.stanza.num_letti === null ? {display: "none"} : {display:"block"}} onClick={() => createLetti(1)}>
                    <p>{langSet.statoLetti}</p>
                </div>
                <div className={status === 2 ? selSelettore : defSelettore}   onClick={() => setStatus(2)} >
                    <p>{"Sensori Bagno"/*langSet.sensoriStanza*/}</p>
                </div>
                <div className={status === 4 ? selSelettore : tipoSensori.includes("Sensore presenza stanza") ? defSelettore : "disabled " + defSelettore}  onClick={tipoSensori.includes("Sensore presenza stanza") ? async () => { await getallarmiassenzastanza(); setStatus(4) } : null}>
                    <p>Allarmi Presenza</p>
                </div>
                <div className={status === 5 ? selSelettore : tipoSensori.includes("Sensore caduta") ? defSelettore : "disabled " + defSelettore} onClick={tipoSensori.includes("Sensore caduta") ? async () => { await getAllarmiCaduta(); setStatus(5) } : null}>
                    <p>{"Allarmi Caduta" /*langSet.allarmiCaduta*/}</p>
                </div>
                {/*<div className={status === 6 ? selSelettore : defSelettore} onClick={() => setStatus(6)}>*/}
                {/*    <p>Sensori BLE</p>*/}
                {/*</div>*/}
            </div>
            <div id="PopupBody">
                <table className="scheda" style={{ display: status === 0 ? "" : "none" }}>
                    <tbody>
                        <tr>
                            <td width="15%" rowSpan={2} className="schedaImg">
                                {langSet.stanzaN}
                                <p style={{ fontSize: "4em", margin: 0 }}>{props.stanza.id}</p>
                                <div style={{ fontSize: ".75em", display: "flex", alignItems: "center", padding: "0 2em" }}>
                                    {stanza.letti.map((letto, i) => {

                                        return (
                                            <>
                                                {
                                                    letto.stato === 'Occupato' ?
                                                        <div key={i} className='dot blue medium'>&nbsp;</div>
                                                        :
                                                    letto.stato === 'Libero' ?
                                                        <div key={i} className='dot green medium'>&nbsp;</div>
                                                        :
                                                    null
                                                }
                                            </>
                                        )
                                    })}
                                    <img src={bedB} alt='bed' style={{ width: "1em" }} />&nbsp;x2
                                </div>
                            </td>
                            <td width="85%">
                                <label>{langSet.posizione}</label>
                                <p>{stanza.percorso}</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div style={{ display: status === 1 ? "" : "none" }}> {/* stato letti */}
                    <table id="TitoliLista" >
                        <tbody>
                            <tr>
                                <td width="20%" style={{ border: 0 }}>{langSet.letto} </td>
                                <td width="20%" style={{ border: 0 }}>{langSet.stato} </td>
                                <td width="60%" style={{ border: 0 }}>{langSet.residente} </td>
                            </tr>
                        </tbody>
                    </table>
                    {stanza.letti.map((letto, i) => {
                        console.log(letto);
                        return (
                            <table key={i} id="Lista" style={{ border: 0 }}>
                                <tbody>
                                    <tr>
                                        <td width="20%" >{i + 1}</td>
                                        <td width="20%" > {letto.stato === 'Occupato' ? <div className='dot blue medium normPosition' /> : letto.stato === 'Libero' ? <div className='dot green medium normPosition' /> : <></>} &nbsp;&nbsp; {letto.stato === 'Occupato' ? langSet.occupato : langSet.libero}</td>
                                        <td width="60%" >{letto.residente}</td>
                                    </tr>
                                </tbody>
                            </table>
                        )
                    })}
                </div>
                <div style={{ display: status === 2 ? "" : "none" }}>
                    <table id="TitoliLista" >
                        <tbody>
                            <tr>
                                <td width="15%" style={{ border: 0 }}>{langSet.nome} </td>
                                <td width="15%" style={{ border: 0 }}>ID </td>
                                <td width="15%" style={{ border: 0 }}>{langSet.tipo} </td>
                                <td width="15%" style={{ border: 0 }}>{langSet.stato} </td>
                                <td width="15%" style={{ border: 0 }}>{langSet.batteria} </td>
                                <td width="25%" style={{ border: 0 }}></td>
                            </tr>
                        </tbody>
                    </table>
                     <Select id="Select" isDisabled={mod === 0 ? true : false}
                        styles={{
                            control: (provided) => ({ ...provided, width: '300px', fontSize: "90%", color: "#2B318A", fontWeight: "600", borderRadius: "20px", overflowX: "hidden", boxShadow: "none", "&:hover": { borderRadius: "25px", boxShadow: "none", width:"300px" } }),
                            input: (provided) => ({ ...provided, color: "#2B318A", fontWeight: "600", fontSize: "90%", textAlign:"center", marginLeft:"10px" }),
                            menu: (provided) => ({ ...provided, width: '300px', fontSize: "90%", color: "#2B318A", fontWeight: "600", borderRadius: "20px", boxShadow: "none",  border: "solid 1px #2B318A", overflowX: "hidden", "&:hover": { borderRadius: "25px", boxShadow: "none", width:"300px" } }),
                            option: (provided) => ({ ...provided, color: "#2B318A", fontWeight: "600", fontSize: "90%", textAlign:"center", marginLeft:"10px", overflowY: "scroll", maxHeight: "25em", overflowX: "hidden"}),
                          }}
                        options={filteredOptions}
                        value={selectedOption}
                        onChange={(selected) => {setSelectedOption(selected); Sens("add", selected.value)}}
                        placeholder={langSet.selezionaSensore}
                        isSearchable
                        onInputChange={(inputValue) => setSearchTerm(inputValue)}
                        /><i style={{ fontSize: "90%", color: "#d3d3d3", display: mod === 1 ? "" : "none" }}>* {langSet.massimo1PerTipologia}</i>
                    <div style={{ overflow: "scroll", maxHeight: "25em" }}>
                        {sensori.length > 0 ?
                            sensori.map((sensore, i) => (
                                <table id="Lista" key={i}>
                                    <tbody>
                                        <tr key={sensore}>
                                            <td width="15%" style={{ padding: 0, paddingLeft: ".5em" }}>{sensore.nome}</td>
                                            <td width="15%" style={{ padding: 0, paddingLeft: ".5em" }}>{sensore.id_sensore ?? sensore.id}</td>
                                            <td width="15%" style={{ padding: 0, paddingLeft: ".5em" }}>{sensore.tipo}</td>
                                            <td width="15%" style={{ padding: 0, paddingLeft: ".5em" }}>{sensore.stato}</td>
                                            <td width="15%" style={{ padding: 0, paddingLeft: ".5em" }}>{sensore.batteria}</td>
                                            <td width="25%" style={{ padding: 0, paddingLeft: ".5em" }}>
                                                {mod === 0 ? null :
                                                    <div className="selettore selettoreFlex selettoreViolet" onClick={() => Sens("del", sensore.id_sensore)}>
                                                        <p>{langSet.disassocia}</p>
                                                        <img src={delet} alt="delete" />
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            )) : <p style={{ textAlign: "center", margin: "1em", display: "" }}>{langSet.nessunSensore}</p>
                        }
                    </div>
                    <div className="navigators">
                        {mod === 0 ?
                            <div
                                className="selettore selettoreFlex selettoreViolet"
                                onClick={() => setMod(1)}
                                style={{ display: accountData.tipo === 4 ? "none" : null }}
                            >
                                <p>{langSet.modifica}</p>
                                <img src={modifica} alt='modifica' style={{ width: "1em" }} />
                            </div>
                            : <>
                                <div
                                    className={defContinua}
                                    onClick={() => salvaModifiche("sensori")}
                                >
                                    <p>{langSet.salva}</p>
                                    <img src={confirmW} alt='confirmW' />
                                </div>
                                <div className="selettore selettoreFlex selettoreViolet" onClick={() => { setMod(0); GetSensoriStanza() }}>
                                    <p>{langSet.annulla}</p>
                                    <img className="icon-popup" src={close} alt="close" />
                                </div>
                            </>
                        }
                    </div>
                </div>
                <div style={{ display: status === 4 ? "" : "none" }}>
                    <table id="TitoliLista">
                        <tbody>
                            <tr>
                                <td width="15%" style={{ border: 0 }}>{langSet.descrizione}</td>
                                <td width="20%" style={{ border: 0 }}>Da - A</td>
                                <td width="12.5%" style={{ border: 0 }}>Allerta Presenza</td>
                                <td width="62.5%" style={{ border: 0 }}></td>
                            </tr>
                        </tbody>
                    </table>
                    <div style={{ overflow: "scroll", minHeight: "20em", maxHeight: "25em" }}>
                        {allarmiassenzastanza.length > 0 ?
                            allarmiassenzastanza.map((allarme, i) => (
                                <table id="Lista" key={i}>
                                    {mod === 0 ?
                                        <tbody>
                                            <tr key={allarme}>
                                                <td width="15%" style={{ padding: 0, paddingLeft: ".5em" }}>{allarme.descrizione}</td>
                                                <td width="20%" style={{ padding: 0, paddingLeft: ".5em" }}>{`${allarme.inizio} - ${allarme.fine}`}</td>
                                                <td width="12.5%" style={{ padding: 0, paddingLeft: ".5em" }}>
                                                    <Switch
                                                        checked={allarme.stato === "Allarme immediato"}
                                                        onColor="#86d3ff"
                                                        offColor="#cccccc"
                                                        uncheckedIcon={false}
                                                        checkedIcon={false}
                                                        height={24}
                                                        width={48}
                                                        disabled
                                                    />
                                                </td>
                                                <td width="62.5%" style={{ padding: 0, paddingLeft: ".5em" }}>
                                                </td>
                                            </tr>
                                        </tbody>
                                        :
                                        <tbody>
                                            <tr key={allarme}>
                                                <td width="15%" style={{ padding: 0, paddingLeft: ".5em" }}>
                                                    {allarme.id ? allarme.descrizione : <input type="text" placeholder={langSet.inserisciDescrizione} onChange={(e) => { allarmiassenzastanza[i].descrizione = e.target.value; setallarmiassenzastanza([...allarmiassenzastanza]) }} style={{ width: "85%" }} />}
                                                </td>
                                                <td width="20%" style={{ padding: 0, paddingLeft: ".5em" }}>
                                                    {allarme.id ? <>{allarme.inizio} - {allarme.fine}</> : <><input type="time" style={{ width: "5.25em" }} onChange={(e) => { allarmiassenzastanza[i].inizio = e.target.value; setallarmiassenzastanza([...allarmiassenzastanza]) }} />&ensp;
                                                        <input type="time" style={{ width: "5.25em" }} onChange={(e) => { allarmiassenzastanza[i].fine = e.target.value; setallarmiassenzastanza([...allarmiassenzastanza]) }} /></>}
                                                </td>
                                                <td width="12.5%" style={{ padding: 0, paddingLeft: ".5em" }}>

                                                    <select value={allarme.stato} style={{ width: "150%", padding:0, display:'none' }} onChange={(e) => { if (allarme.id) { allarmiassenzastanzaSwitch(allarme.id, 'edit', e.target.value); } allarmiassenzastanza[i].stato = e.target.value; setallarmiassenzastanza([...allarmiassenzastanza]) }} required>
                                                        <option value="0" disabled>- - -</option>
                                                        {selettoreAllarmiassenzastanza.map((currSel, index) => <option key={index} value={currSel}>{currSel}</option>)}
                                                    </select>

                                                    <Switch
                                                        onChange={(checked) => {
                                                            const newValue = checked ? "Allarme immediato" : "Allarme disabilitato";
                                                            if (allarme.id) {
                                                                allarmiassenzastanzaSwitch(allarme.id, 'edit', newValue);
                                                            }
                                                            allarmiassenzastanza[i].stato = newValue;
                                                            setallarmiassenzastanza([...allarmiassenzastanza]);
                                                        }}
                                                        checked={allarme.stato === "Allarme immediato"}
                                                        onColor="#86d3ff"
                                                        offColor="#cccccc"
                                                        uncheckedIcon={false}
                                                        checkedIcon={false}
                                                        height={24}
                                                        width={48}
                                                    />

                                                </td>
                                                <td width="62.5%" style={{ padding: 0, paddingLeft: ".5em" }}>
                                                    <div className="selettore selettoreFlex selettoreBorder" onClick={() => allarmiassenzastanzaSwitch(allarme.id, 'del')}>
                                                        <p>Elimina</p>
                                                        <img src={delet} alt="delete" />
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    }
                                </table>
                            )) :
                            <p style={{ textAlign: "center", margin: "1em" }}>{langSet.nessunAllarme}</p>
                        }
                    </div>
                    <div className="navigators">
                        {mod === 0 ?
                            <div
                                className="selettore selettoreFlex selettoreViolet"
                                onClick={() => setMod(1)}
                                style={{ display: accountData.tipo === 4 ? "none" : null }}
                            >
                                <p>{langSet.modifica}</p>
                                <img src={modifica} alt='modifica' className="icon-popup" />
                            </div>
                            : <>
                            <div className="selettore selettoreFlex selettorePurple normPosition" onClick={() => allarmiassenzastanzaSwitch('', 'add')} >
                                <p>{langSet.aggiungi}</p>
                                <img src={addW} alt="add" className="icon-popup" />
                            </div>
                            <div className={defContinua} onClick={() => salvaModifiche("allarmipresenzaStanza")}>
                                <p>{langSet.salva}</p>
                                <img src={confirmW} alt='arrow' className="icon-popup" />
                            </div>
                            <div className="selettore selettoreFlex selettoreViolet" onClick={async () => { await getallarmiassenzastanza(); setMod(0); }}>
                                <p>{langSet.annulla}</p>
                                <img src={close} alt="close" className="icon-popup" />
                            </div>
                            <div className="selettore selettoreFlex selettoreBorder" onClick={() => openProfiloPopup("salva")}>
                                <p>Salva Profilo</p>
                                <img src={save} alt="save" className="icon-popup" />
                            </div>
                            <div className="selettore selettoreFlex selettoreBorder" onClick={() => openProfiloPopup("carica")}>
                                <p>Carica Profilo</p>
                                <img src={carica} alt="carica" className="icon-popup" />
                            </div>
                        </>
                    }
                    </div>

                </div>
                <div style={{ display: status === 5 ? "" : "none" }}>
                    <table id="TitoliLista">
                        <tbody>
                            <tr>
                                <td width="15%" style={{ border: 0 }}>{langSet.descrizione}</td>
                                <td width="20%" style={{ border: 0 }}>Da - A</td>
                                <td width="12.5%" style={{ border: 0 }}>Allerta Caduta</td>
                                <td width="62.5%" style={{ border: 0 }}></td>
                            </tr>
                        </tbody>
                    </table>
                    <div style={{ overflow: "scroll", minHeight: "20em", maxHeight: "25em" }}>
                        {allarmiCaduta.length > 0 ?
                            allarmiCaduta.map((allarme, i) => (
                                <table id="Lista" key={i}>
                                    {mod === 0 ?
                                        <tbody>
                                            <tr key={allarme}>
                                                <td width="15%" style={{ padding: 0, paddingLeft: ".5em" }}>{allarme.descrizione}</td>
                                                <td width="20%" style={{ padding: 0, paddingLeft: ".5em" }}>{`${allarme.inizio} - ${allarme.fine}`}</td>
                                                <td width="12.5%" style={{ padding: 0, paddingLeft: ".5em" }}>
                                                    {/*{allarme.stato}*/}
                                                    <Switch
                                                        checked={allarme.stato === "Allarme immediato"}
                                                        onColor="#86d3ff"
                                                        offColor="#cccccc"
                                                        uncheckedIcon={false}
                                                        checkedIcon={false}
                                                        height={24}
                                                        width={48}
                                                        disabled


                                                    />
                                                </td>
                                                <td width="62.5%" style={{ padding: 0, paddingLeft: ".5em" }}>
                                                </td>
                                            </tr>
                                        </tbody>
                                        :
                                        <tbody>
                                            <tr key={allarme}>
                                                <td width="15%" style={{ padding: 0, paddingLeft: ".5em" }}>
                                                    {allarme.id ? allarme.descrizione : <input type="text" placeholder={langSet.inserisciDescrizione} onChange={(e) => { allarmiCaduta[i].descrizione = e.target.value; setAllarmiCaduta([...allarmiCaduta]) }} style={{ width: "85%" }} />}
                                                </td>
                                                <td width="20%" style={{ padding: 0, paddingLeft: ".5em" }}>
                                                    {allarme.id ? <>{allarme.inizio} - {allarme.fine}</> : <><input type="time" style={{ width: "5.25em" }} onChange={(e) => { allarmiCaduta[i].inizio = e.target.value; setAllarmiCaduta([...allarmiCaduta]) }} />&ensp;
                                                        <input type="time" style={{ width: "5.25em" }} onChange={(e) => { allarmiCaduta[i].fine = e.target.value; setAllarmiCaduta([...allarmiCaduta]) }} /></>}
                                                </td>
                                                <td width="12.5%" style={{padding: 0, paddingLeft: ".5em"}}>
                                                    {/*<select value={allarme.stato} style={{width: "95%"}}*/}
                                                    {/*        onChange={(e) => {*/}
                                                    {/*            if (allarme.id) {*/}
                                                    {/*                allarmiCadutaSwitch(allarme.id, 'edit', e.target.value);*/}
                                                    {/*            }*/}
                                                    {/*            allarmiCaduta[i].stato = e.target.value;*/}
                                                    {/*            setAllarmiCaduta([...allarmiCaduta])*/}
                                                    {/*        }} required>*/}
                                                    {/*    <option value="0" disabled>- - -</option>*/}
                                                    {/*    {selettoreAllarmiLetto.map((currSel, index) => <option*/}
                                                    {/*        key={index} value={currSel}>{currSel}</option>)}*/}
                                                    {/*</select>*/}

                                                    <Switch
                                                        onChange={(checked) => {
                                                            const newValue = checked ? "Allarme immediato" : "Allarme disabilitato";
                                                            if (allarme.id) {
                                                                allarmiCadutaSwitch(allarme.id, 'edit', newValue);
                                                            }
                                                            allarmiCaduta[i].stato = newValue;
                                                            setAllarmiCaduta([...allarmiCaduta]);
                                                        }}
                                                        checked={allarme.stato === "Allarme immediato"}
                                                        onColor="#86d3ff"
                                                        offColor="#cccccc"
                                                        uncheckedIcon={false}
                                                        checkedIcon={false}
                                                        height={24}
                                                        width={48}
                                                    />

                                                </td>
                                                <td width="62.5%" style={{padding: 0, paddingLeft: ".5em"}}>
                                                    <div className="selettore selettoreFlex selettoreBorder"
                                                         onClick={() => allarmiCadutaSwitch(allarme.id, 'del')}>
                                                        <p>Elimina</p>
                                                        <img src={delet} alt="delete"/>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    }
                                </table>
                            )) :
                            <p style={{textAlign: "center", margin: "1em"}}>{langSet.nessunAllarme}</p>
                        }
                    </div>
                    {status === 5 ? profilopopup : null}
                    <div className="navigators">
                        {mod === 0 ?

                            <div className="selettore selettoreFlex selettoreViolet" onClick={() => setMod(1)}
                            >
                                <p>{langSet.modifica}</p>
                                <img src={modifica} alt='modifica' className="icon-popup" style={{width: "1em"}}/>
                            </div>
                            : <>
                                <div className="selettore selettoreFlex selettorePurple normPosition"
                                     onClick={() => allarmiCadutaSwitch('', 'add')}>
                                    <p>{langSet.aggiungi}</p>
                                    <img src={addW} alt="add" className="icon-popup"/>
                                </div>
                                <div className={defContinua} onClick={() => salvaModifiche("allarmiCaduta")}>
                                    <p>{langSet.salva}</p>
                                    <img src={confirmW} alt='arrow' className="icon-popup"/>
                                </div>
                                <div className="selettore selettoreFlex selettoreViolet" onClick={async () => { await getAllarmiCaduta(); setMod(0); }}>
                                    <p>{langSet.annulla}</p>
                                    <img src={close} alt="close" className="icon-popup" />
                                </div>
                                <div className="selettore selettoreFlex selettoreBorder moblie1" onClick={() => openProfiloPopup("salva")}>
                                    <p>Salva Profilo</p>
                                    <img src={save} alt="save" className="icon-popup" />
                                </div>
                                <div className="selettore selettoreFlex selettoreBorder moblie1" onClick={() => openProfiloPopup("carica")}>
                                    <p>Carica Profilo</p>
                                    <img src={carica} alt="carica" className="icon-popup" />
                                </div>
                            </>
                        }
                    </div>

                </div>

                <div style={{ display: status === 6 ? "" : "none" }}>

                    <Blebeacon  />

                </div>
            </div>

        </>
    );
}

export default SchedaStanza;