import React, { useState, useEffect, useRef } from 'react';
import Draggable from 'react-draggable';
import logo from '../imgs/logo3D.png';
import bell from '../imgs/icons/bell.svg';
import arrow from '../imgs/icons/arrow.svg';
import arrowhite from '../imgs/icons/arrow-white.svg';

import busybed from "../imgs/icons/Busybed.svg";
import falldetected from "../imgs/icons/falldetected.svg";
import emptyroom from "../imgs/icons/emmptyroom.svg";
import offlinebedroom from "../imgs/icons/offlinebedroom.svg";
import busybathroom from "../imgs/icons/busybathroom.svg";
import emptybathroom from "../imgs/icons/emptybathroom.svg";
import offlinebathroom from "../imgs/icons/offlinebathroom.svg";

import it from '../imgs/it.png';
import de from '../imgs/de.png';
import en from '../imgs/en.png';

import user from '../imgs/user_icon.svg';

import logout from '../imgs/icons/logout.svg';
import PopupComp from './Popup.js';

import authNode from 'node/auth/auth.js';
import struttureNode from "node/struttura/struttura.js";
import notificheNode from 'node/notifiche/notifiche.js';
import info from '../imgs/icons/info.circle 1.svg';
import i18n from '../i18n.js';
import lang from "../lang.json";
import addW from "../imgs/icons/addW.svg";

const langSet = lang[localStorage.getItem("lang")].header;
const langAlarms = lang[localStorage.getItem("lang")].alarms;

export default function Header(props) {
    const struttura = localStorage.getItem("struttura");
    const accountData = JSON.parse(localStorage.getItem("account"));
    const [strutture, setStrutture] = useState([]);
    const [strutturaSel, setStrutturaSel] = useState();
    const [infosPopup, setInfosPopup] = useState(['']);
    const [lang, setLang] = useState(localStorage.getItem("lang"));
    const [isHovered, setIsHovered] = useState(false);

    const divRefStruttura = useRef(null);
    const divRefAccount = useRef(null);
    const divRefLegenda = useRef(null);



    // const [selectedOption, setSelectedOption] = useState(null);
    //
    // const handleChange = (event) => {
    //     setSelectedOption(event.target.value);
    //     console.log(`Option selected:`, event.target.value);
    // };

    //
    // const [selectedStruttura, setSelectedStruttura] = useState(localStorage.getItem("struttura") || 'defaultValue');
    //
    // useEffect(() => {
    //     // This effect ensures the localStorage is updated when the component mounts
    //     localStorage.setItem("struttura", selectedStruttura);
    // }, [selectedStruttura]);


    // const handleIconHover = () => {
    //     setIsHovered(true);
    // };
    //
    // const handleIconLeave = () => {
    //     setIsHovered(false);
    // };
    if (props.title !== 'Sensori' && struttura === 'Magazzino generale') StrutturaSel(accountData.db);
    const [notifiche, setNotifiche] = useState(0);


    let perm = accountData.tipo;
    if (perm === 0) perm = "Admin";
    else if (perm === 1) perm = langSet.itInterno;
    else if (perm === 2) perm = langSet.manutEsterno;
    else if (perm === 3) perm = langSet.coordinatoreInfermiere;
    else if (perm === 4) perm = langSet.personaleDiCorsia;

    // funzione che rimuove i dati nella local storage e reindirizza alla pagina di login
    function Logout() {
        localStorage.removeItem("login");
        localStorage.removeItem("account");
        localStorage.removeItem("struttura");
        window.location.href = "/";
    }

    function openSottoSel(id) {
        let sottoSel = document.getElementById(id);
        if (sottoSel.style.opacity === '0') {
            sottoSel.style.display = "block";
            setTimeout(() => {
                sottoSel.style.opacity = 1;
            }, 0);
        } else {
            sottoSel.style.opacity = 0;
            setTimeout(() => {
                sottoSel.style.display = "none";
            }, 200);
        }
    }
    // funzione per listare le strutture
    async function getStrutture() {
        await struttureNode.getListaStrutture(accountData.token).then((res) => {
            if (res.result) {
                setStrutture(res.data);
            }
        });
    }
     // funzione conteggio notifiche
    async function getNotifiche() {
        await notificheNode.getNotifiche(accountData.token).then((res) => {
            if (res.result) {
                let notifiche = 0;
                for (let i = 0; i < res.data.length; i++) {
                    if (res.data[i].confermata === false) notifiche++;
                }
                setNotifiche(notifiche);
            }
        })
    }
    useEffect(() => {
        getNotifiche();
        const interval = setInterval(() => {
            getNotifiche();
        }, 10000);
        return () => clearInterval(interval);
    });
    
    // funzione per selezionare la struttura e aggiornare la pagina con i dati della struttura selezionata
    async function StrutturaSel(strutturaNew) {

        // console.log("ciao ciao ciao ciao ciao ciao");
        //
        // console.log(strutturaNew);

        if (strutturaNew !== "Magazzino generale" && strutturaNew !== struttura) switchStruttura(strutturaNew); //strutturaNew !== struttura è un controllo per il cambio struttura, altrimenti viene eseguitro due volte
        props.impostaStruttura(strutturaNew);
        setStrutturaSel(strutturaNew);
        localStorage.setItem("struttura", strutturaNew);
        if (props.title === 'Sensori' && strutturaNew !== struttura) props.refreshSensori();
        if (props.title === 'Struttura') props.refreshStruttura();
        if (props.title === 'Utenti') props.refreshUtenti();
        if (props.title === 'Notifiche') props.refreshNotifiche();
        if (props.title === 'Residenti') props.refreshResidenti();
        if (props.title === 'Dashboard') props.refreshDashboard();
        // aspetto 1 secondo per far si che la pagina si aggiorni prima di chiudere il popup
        await notificheNode.getNotifiche(accountData.token).then((res) => {
            if (res.result) {
                let notifiche = 0;
                for (let i = 0; i < res.data.length; i++) {
                    if (res.data[i].confermata === false) notifiche++;
                }
                setNotifiche(notifiche);
            }
        })
    }

    useEffect(() => {
        StrutturaSel(localStorage.getItem("struttura"));
        if (accountData.tipo === 0) getStrutture();
    }, [struttura]);

    useEffect(() => {
        if (strutturaSel === "null") {
            openPopup("alarm", langAlarms.erroreStruttura, "logout");
        }
    }, [strutturaSel]);

    //richiamo il metodo per il logout automatico
    function Slogga() {
        openPopup("alarm", langAlarms.logoutAutomatico, "logout");
    }
    // funzione per cambiare struttura
    async function switchStruttura(id) {
        console.log(id)
        await authNode.switchStruttura(accountData.token, id).then((res) => {
            let account = JSON.parse(localStorage.getItem("account")); //prendo i dati dell'account dal local storage
            account.mqtt_password = res.message; //aggiorno la password mqtt
            console.log(res.message)
            console.log(res.result)
            account.db = id; //aggiorno il db
            localStorage.setItem("account", JSON.stringify(account)); //salvo i dati dell'account nel local storage
            console.log("Password mqtt aggiornata: " + localStorage.getItem("account")); //da controllare! 
        });
    }

    function openPopup(category, info, operation, data) {
        document.getElementById("PopupHeader").style.display = "flex";
        setInfosPopup([{ category, info, operation, data }]);
    }

    function closePopup() {
        document.getElementById("PopupHeader").style.display = "none";
        setInfosPopup(['']);
    }

    function changeLang(lang) {
        i18n.changeLanguage(lang);

        localStorage.setItem("lang", lang);
        setLang(lang);
        window.location.reload();
    }

    function closeSottoSel() {
        // let sottoSel = document.getElementById("struttura");
        let sottoSel1 = document.getElementById("account");
        let sottoSel2 = document.getElementById("legenda");

        // if (sottoSel.style.opacity === '1' && sottoSel.style.display !== 'none') {
        //     sottoSel.style.opacity = 0;
        //     setTimeout(() => {
        //         sottoSel.style.display = "none";
        //     }, 200);
        // }
        if (sottoSel1.style.opacity === '1') {
            sottoSel1.style.opacity = 0;
            setTimeout(() => {
                    sottoSel1.style.display = "none";
                }
                , 200);
        }
        if (sottoSel2.style.opacity === '1') {
            sottoSel2.style.opacity = 0;
            setTimeout(() => {
                    sottoSel2.style.display = "none";
                }
                , 200);
        }
    }


    useEffect(() => {
        // Function to handle clicks outside the div
        function handleClickOutside(event) {
            // if (divRefStruttura.current && !divRefStruttura.current.contains(event.target)) {
            //     // Clicked outside the div, so close it
            //     closeSottoSel("struttura");
            // }
            if (divRefAccount.current && !divRefAccount.current.contains(event.target)) {
                // Clicked outside the div, so close it
                closeSottoSel("account");
            }
            if (divRefAccount.current && !divRefAccount.current.contains(event.target)) {
                // Clicked outside the div, so close it
                closeSottoSel("legenda");
            }

        }

        // Add event listener for clicks on the entire document
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    return (
        <>
            <div id="PopupHeader">
                <Draggable handle="#handle" position={{x: 0, y: 0}}>
                    <div className="popupContent">
                        <PopupComp info={infosPopup} Logout={Logout} closePopup={closePopup}/>
                    </div>
                </Draggable>
            </div>
            <div className='headerSX' style={{color: "white"}}>

                <img src={logo} alt='logo' style={{ cursor: 'default' }} className='logoHeader'/>

            </div>

            {accountData.tipo === 0 ?
                <>
                    <div className='headerCX'>

                        <div className='selettore selettoreFlex header-center-select'
                            //style={{ position: "absolute", left: "45%" }}
                             ref={divRefStruttura}
                             onClick={() => openSottoSel("struttura")}>

                            <p>{strutturaSel === null ? "Seleziona struttura" : strutturaSel}</p>&nbsp;
                            <img src={arrow} alt='arrow'/>
                            <div className='sottoSelettore scrol-item' id="struttura" style={{fontSize: "85%", overflow: "scroll" }} >
                                {props.title === 'Sensori' ?
                                    <div id="Lista" className='row' onClick={() => StrutturaSel("Magazzino generale")}>
                                        <p>Magazzino generale</p>
                                    </div>
                                    :
                                    null
                                }
                                {strutture.map((struttura, i) => {
                                    return (
                                        <div id="Lista" className='row' key={i} onClick={() => {
                                            StrutturaSel(struttura.id);
                                            localStorage.setItem('visualizzazione', "Nucleo")
                                        }}>
                                            <p>{struttura.nome}</p>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                    </div>
                </> :
                <>
                </>
            }


            {accountData.tipo === 0 ?
                <>
                    <div className='headerCX' style={{ display: "none" }}>
                        <div className='selettore selettoreFlex header-center-select'
                            //style={{ position: "absolute", left: "45%" }}
                             ref={divRefStruttura}
                             onClick={() => openSottoSel("struttura")}>

                            <p>{strutturaSel === null ? "Seleziona struttura" : strutturaSel}</p>&nbsp;
                            <img src={arrow} alt='arrow'/>
                            <div className='sottoSelettore' id="struttura" style={{fontSize: "85%"}}>
                                {props.title === 'Sensori' ?
                                    <div id="Lista" className='row' onClick={() => StrutturaSel("Magazzino generale")}>
                                        <p>Magazzino generale</p>
                                    </div>
                                    :
                                    null
                                }-
                                {strutture.map((struttura, i) => {
                                    return (
                                        <div id="Lista" className='row' key={i} onClick={() => {
                                            StrutturaSel(struttura.id);
                                            localStorage.setItem('visualizzazione', "Nucleo")
                                        }}>
                                            <p>{struttura.nome}</p>
                                        </div>
                                    );
                                })}

                            </div>
                        </div>
                    </div>
                </> :
                <>
                </>
            }

            <div className='headerDX'>
                <div className="container">

                    <img src={info} className='bell' alt='Notifiche' ref={divRefLegenda}
                         onMouseDown={() => openSottoSel("legenda")}/>

                    <div className='sottoSelettore width-height-auto' id="legenda"
                         style={{fontSize: "20px", position: "absolute"}}>
                        <div className="legenda">
                            <div className="legendaItem">
                                <img src={falldetected} alt="allarme"/>
                                <p>Possibile Caduta</p>
                            </div>
                            <div className="legendaItem">
                                <img src={busybed} alt="occupato"/>
                                <p>Letto Occupato</p>
                            </div>
                            <div className="legendaItem">
                                <img src={emptyroom} alt="libero"/>
                                <p>Letto Vuoto</p>
                            </div>
                            <div className="legendaItem">
                                <img src={busybathroom} alt="occupato"/>
                                <p>Bagno Occupato</p>
                            </div>

                            <div className="legendaItem">
                                <img src={emptybathroom} alt="occupato"/>
                                <p>Bagno Libero</p>
                            </div>

                            <div className="legendaItem">
                                <div style={{
                                    borderRadius: '5px 20px',
                                    width: '15px',
                                    padding: '13px',
                                    border: '4px solid rgb(29, 175, 237)',
                                    backgroundColor: 'rgb(255, 255, 255)',
                                    color: 'black',
                                    fontWeight: '600',
                                    fontSize: '1rem',
                                    justifyContent: "center",
                                    display: "flex",
                                    height: '10px',
                                    alignItems: 'center',
                                    marginTop: "10px"
                                }}>
                                    <img src={emptyroom} alt="libero" style={{width: '45px'}}/>
                                </div>
                                <p style={{marginLeft: '10px'}}> Presenza Stanza</p>
                            </div>

                            <div className="legendaItem">
                                <div style={{
                                    borderRadius: '5px 20px',
                                    width: '15px',
                                    padding: '13px',
                                    border: '1px solid lightgray',
                                    backgroundColor: 'rgb(255, 255, 255)',
                                    color: 'black',
                                    fontWeight: '600',
                                    fontSize: '1rem',
                                    justifyContent: "center",
                                    display: "flex",
                                    height: '10px',
                                    alignItems: 'center',
                                    marginTop: "10px"
                                }}>
                                    <img src={emptyroom} alt="libero" style={{width: '45px'}}/>
                                </div>
                                <p style={{marginLeft: '10px'}}>Stanza Vuota</p>
                            </div>

                            <div className="legendaItem">
                                <img src={offlinebedroom} alt="offline"/>
                                <p>Sensore offline</p>
                            </div>

                        </div>
                    </div>
                </div>
                {/*
                    notifiche > 0 ?
                        <div className="dot red" style={{ position: "absolute", top: ".5em", left: "1em" }} /> : null
                */}

                {/*<div className='selettore selettoreFlex selettorePurple' style={{width: "9em", border:"3px solid white"}}*/}
                {/*     onClick={() => openPopup('ticket')}>*/}
                {/*    <p>Richiedi supporto</p>*/}
                {/*</div>*/}

                <div className='selettore selettoreFlex selettorePurple' ref={divRefAccount}
                     style={{border: "3px solid white"}}
                     onClick={() => openSottoSel("account")}>
                    <div className="firstLettert">
                        {/*<p style={{fontSize: "70%", color: "#A8B1CE"}}>{perm}</p>*/}
                        <p className="mobileBlock"
                           style={{ marginRight: "1em"}}>{accountData.cognome.charAt(0).toUpperCase() + " " + accountData.nome.charAt(0).toUpperCase()}</p>
                    </div>
                    <img src={arrowhite} alt='arrow' className='arrow' style={{color: "white"}}/>
                    <div className='sottoSelettore' id="account" onClick={Slogga}>
                        <div className='row'>
                            <p className='logout'>Logout</p>
                            <img src={logout} alt='logout'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}