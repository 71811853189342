import React from "react";
import Alert from "../imgs/icons/Alert.svg";
import departmentDoctor from "../imgs/icons/department-doctor-svgrepo-com 1.svg";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";

const Nucleo = (props) => {
  console.log(props.piano);
  const { t } = useTranslation();
  const lang = localStorage.getItem("lang")
  if (lang !== i18n.language) {
    i18n.changeLanguage(lang);
  }
  if (props.piano.sensori.length > 0) {
    const hasAlarms = props.piano.sensori.some((item) => item.banner !== 0);

    const gradientColor = hasAlarms ? "#FBE6E5" : "#F2F5FF";
    const alarmText = hasAlarms ? t("RilevateAnomalienelPiano".toLowerCase()) : t("NessunaAllerta".toLowerCase());
    const alarmDotClass = hasAlarms ? "red" : "green";



    return (
      <div id={hasAlarms ? "GrigliaAlarm" : "Griglia"}
        style={{ background: `linear-gradient(180deg, #FFFFFF 45%, ${gradientColor} 60%)` }}>
        {/*<table style={{ marginTop: "20px" }}>*/}
        {/*  <tbody>*/}
        {/*    <tr>*/}
        {/*      <td style={{  textAlign: "center", color: "black", fontWeight: "800", fontSize: "32px", fontFamily: 'Titillium Web' }} width="100%">*/}
        {/*      <td style={{ textAlign: "center", marginTop: "5%", paddingBottom: "40%", color: "#2B318A", fontWeight: "600", fontSize: "1em" }} width="100%">*/}
        {/*        {hasAlarms ? (*/}
        {/*          <div className="lamp-effect" style={{ display: "flex", justifyContent: "right", position: "absolute", top: "5%", right: "5%" }}>*/}
        {/*            <img src={Alert} style={{ width: "35px", height: "35px", alignSelf: "flex-end" }} />*/}
        {/*          </div>*/}
        {/*        ) : (*/}
        {/*          <div style={{ width: "40px" }}></div>*/}
        {/*        )}*/}
        {/*        */}
        {/*      </td>*/}
        {/*      {props.piano.descrizione}*/}
        {/*      </td> */}
        {/*    </tr>*/}
        {/*    <tr>*/}
        {/*      <td colSpan={2} style={{ fontSize: "80%", color: "#6A6E83", width: "100%" }}></td>*/}
        {/*    </tr>*/}
        {/*    <tr>*/}
        {/*      <td></td>*/}
        {/*    </tr>*/}
        {/*    <tr>*/}
        {/*      <td colSpan={4}>*/}
        {/*        <br /><br />*/}
        {/*        <div className="alarmG" style={{ fontSize: "80%", textAlign: "center" }}>*/}
        {/*          &nbsp; {alarmText}*/}
        {/*          <div className="right">*/}
        {/*            <div className={`dot ${alarmDotClass}`} style={{ right: "0" }}>&nbsp;</div>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </td>*/}
        {/*    </tr>*/}
        {/*  </tbody>*/}
        {/*</table>*/}
        <table style={{ marginTop: "20px" }}>
          <tbody>
            <tr>
              <td style={{
                textAlign: "center",
                color: "black",
                fontWeight: "800",
                fontSize: "32px",
                fontFamily: 'Titillium Web'
              }} width="100%">
                {/* Correctly closed the first <td> element before opening the second one */}
                {/*<td style={{*/}
                {/*  textAlign: "center",*/}
                {/*  marginTop: "5%",*/}
                {/*  paddingBottom: "40%",*/}
                {/*  color: "#2B318A",*/}
                {/*  fontWeight: "600",*/}
                {/*  fontSize: "1em"*/}
                {/*}} width="100%">*/}
                {hasAlarms ? (
                  <div className="lamp-effect" style={{
                    display: "flex",
                    justifyContent: "right",
                    position: "absolute",
                    top: "5%",
                    right: "5%"
                  }}>
                    <img src={Alert} style={{ width: "35px", height: "35px", alignSelf: "flex-end", cursor: "default" }} />
                  </div>
                ) : (
                  <div style={{ width: "40px" }}></div>
                )}
                {props.piano.descrizione}
              </td>
              {/*</td>*/}
              {/* This closing tag was incorrect and unnecessary. Removed it. */}
            </tr>
            <tr>
              <td colSpan={2} style={{ fontSize: "80%", color: "#6A6E83", width: "100%" }}></td>
            </tr>
            {/* <tr>
            <td></td>
          </tr> */}
            <tr>
              {/* Corrected the colSpan to 2 since there are only two columns in this row */}
              <td colSpan={2}>
                <br /><br />
                <div className="alarmG" style={{ fontSize: "80%", textAlign: "center" }}>
                  &nbsp; {alarmText}
                  <div className="right">
                    <div className={`dot ${alarmDotClass}`} style={{ right: "0" }}>&nbsp;</div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

      </div>
    );
  }
}

export default Nucleo;
