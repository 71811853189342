import React from "react";
import alarmY from "../imgs/icons/alarmY.svg";
import busyescalator from "../imgs/icons/busyescalator.svg";
import busygap from "../imgs/icons/busygap.svg";
import busyhall from "../imgs/icons/busyhall.svg";
import emptyescalator from "../imgs/icons/emptyescalator.svg";
import emptygap from "../imgs/icons/emptygap.svg";
import emptyhall from "../imgs/icons/emptyhall.svg";
import falldetected from "../imgs/icons/falldetected.svg";
import offlinebathroom from "../imgs/icons/offlinebathroom.svg";
import offlinepassage from "../imgs/icons/offlinepassage.svg";
import offlineescalator from "../imgs/icons/offlineescalator.svg";
import busybathroom from "../imgs/icons/busybathroom.svg";
import emptybathroom from "../imgs/icons/emptybathroom.svg";
import Alert from "../imgs/icons/Alert.svg";
import notifiche from "node/notifiche/notifiche";
import i18n from "../i18n";
import { useTranslation } from 'react-i18next';
import busylivingroom from "../imgs/icons/busylivingroom.svg";
import freelivingroom from "../imgs/icons/freelivingroom.svg";
import busykitchen from "../imgs/icons/Component 7.svg";
import frekitchen from "../imgs/icons/Component 6.svg";

// Componente che rappresenta un ambiente nella griglia o nella lista delle stanze 
const Ambiente = (props) => {
    const { t } = useTranslation();
    const lang = localStorage.getItem("lang")
    if (lang !== i18n.language) {
    i18n.changeLanguage(lang);
    }
    const accountData = JSON.parse(localStorage.getItem("account"));
    // console.log(props.stanza);
function getimgState(stanza) {
    let sensoreAmbienteCaduta = stanza.sensori.find((item) => item.tipo === "Sensore caduta")
    let sensoreAmbientepresenza = stanza.sensori.find((item) => item.tipo === "Sensore presenza stanza")
    if (sensoreAmbientepresenza === undefined) { sensoreAmbientepresenza = { tipo: "Sensore presenza stanza", stato: "Assente", banner: -1 }; } // se non ha un banner, mostra "offline"
        if (sensoreAmbienteCaduta === undefined) { sensoreAmbienteCaduta = { tipo: "Sensore caduta", stato: "Offline", banner: -1 }; } // se non ha un banner, mostra "offline"
        if (sensoreAmbienteCaduta && sensoreAmbienteCaduta.stato === "Caduta rilevata!") {
            console.log("caduta");
            return falldetected;

        }else {
            // console.log(stanza.tipo)
            switch (stanza.tipo) {
                case "Bagno":
                    if (stanza.sensori.every((item) => item.stato == "Offline")) {
                        return offlinebathroom;
                    }
                    if (sensoreAmbientepresenza && sensoreAmbientepresenza.stato === "Presente") {
                        console.log("presente");
                        return busybathroom;
                    }else if (sensoreAmbientepresenza && sensoreAmbientepresenza.stato === "Assente") {
                        return emptybathroom;
                    }
                    break;
                case "Varco":
                    if (stanza.sensori.every((item) => item.stato == "Offline")) {
                        return offlinepassage;
                    }
                    if (sensoreAmbientepresenza && sensoreAmbientepresenza.stato === "Presente") {
                        console.log("presente");
                        return busygap;
                    }else if (sensoreAmbientepresenza && sensoreAmbientepresenza.stato === "Assente") {
                        return emptygap;
                    } 
                    break;
                case "Ingresso Principale":

                    if (stanza.sensori.every((item) => item.stato == "Offline")) {
                        return offlinepassage;
                    }
                    if (sensoreAmbientepresenza && sensoreAmbientepresenza.stato === "Presente") {
                        console.log("presente");
                        return busygap;
                    }else if (sensoreAmbientepresenza && sensoreAmbientepresenza.stato === "Assente") {
                        return emptygap;
                    }
                    break;
                case "Ingresso Animazione":
                    if (stanza.sensori.every((item) => item.stato == "Offline")) {
                        return offlinepassage;
                    }
                    if (sensoreAmbientepresenza && sensoreAmbientepresenza.stato === "Presente") {
                        console.log("presente");
                        return busygap;
                    }else if (sensoreAmbientepresenza && sensoreAmbientepresenza.stato === "Assente") {
                        return emptygap;
                    }
                    break;
                case "Ingresso Centro Assistenza Diurno":
                    if (stanza.sensori.every((item) => item.stato == "Offline")) {
                        return offlinepassage;
                    }
                    if (sensoreAmbientepresenza && sensoreAmbientepresenza.stato === "Presente") {
                        console.log("presente");
                        return busygap;
                    }else if (sensoreAmbientepresenza && sensoreAmbientepresenza.stato === "Assente") {
                        return emptygap;
                    }
                    break;
                case "Usciata Emergenza":
                    if (stanza.sensori.every((item) => item.stato == "Offline")) {
                        return offlinepassage;
                    }
                    if (sensoreAmbientepresenza && sensoreAmbientepresenza.stato === "Presente") {
                        console.log("presente");
                        return busygap;
                    }else if (sensoreAmbientepresenza && sensoreAmbientepresenza.stato === "Assente") {
                        return emptygap;
                    }
                    break;
                case "Uscita Principale":
                    if (stanza.sensori.every((item) => item.stato == "Offline")) {
                        return offlinepassage;
                    }
                    if (sensoreAmbientepresenza && sensoreAmbientepresenza.stato === "Presente") {
                        console.log("presente");
                        return busygap;
                    }else if (sensoreAmbientepresenza && sensoreAmbientepresenza.stato === "Assente") {
                        return emptygap;
                    }
                    break;
                case "Uscita Animazione":
                    if (stanza.sensori.every((item) => item.stato == "Offline")) {
                        return offlinepassage;
                    }
                    if (sensoreAmbientepresenza && sensoreAmbientepresenza.stato === "Presente") {
                        console.log("presente");
                        return busygap;
                    }else if (sensoreAmbientepresenza && sensoreAmbientepresenza.stato === "Assente") {
                        return emptygap;
                    }
                    break;
                case "Uscita Centro Assistenza Diurno":
                    if (stanza.sensori.every((item) => item.stato == "Offline")) {
                        return offlinepassage;
                    }
                    if (sensoreAmbientepresenza && sensoreAmbientepresenza.stato === "Presente") {
                        return busygap;
                    }else if (sensoreAmbientepresenza && sensoreAmbientepresenza.stato === "Assente") {
                        return emptygap;
                    }
                    break;
                case "Cucina":
                    if (stanza.sensori.every((item) => item.stato == "Offline")) {
                        return frekitchen;
                    }
                    if (sensoreAmbientepresenza && sensoreAmbientepresenza.stato === "Presente") {
                        return busykitchen;
                    }else if (sensoreAmbientepresenza && sensoreAmbientepresenza.stato === "Assente") {
                        return frekitchen;
                    }
                    break;
                case "Soggiorno":
                    if (stanza.sensori.every((item) => item.stato == "Offline")) {
                        return freelivingroom;
                    }
                    if (sensoreAmbientepresenza && sensoreAmbientepresenza.stato === "Presente") {
                        return busylivingroom;
                    }else if (sensoreAmbientepresenza && sensoreAmbientepresenza.stato === "Assente") {
                        return freelivingroom;
                    }
                    break;
                case "Ascensore" :
                    if ( stanza.sensori.every((item) => item.stato == "Offline")) {
                        return offlineescalator;
                    }
                    if (sensoreAmbientepresenza && sensoreAmbientepresenza.stato === "Presente") {
                        console.log("presente");
                        return busyescalator;
                    }else if (sensoreAmbientepresenza && sensoreAmbientepresenza.stato === "Assente") {
                        return emptyescalator;
                    }

                    break;
                    default:
                        return null;
                        break;

        }
    }

}
async function getNotificheNonConfermate(token, id_residente, id_stanza) { // funzione che prende le notifiche
    //props.loading(true); // attiva il caricamento
    
    let notificheNonConfermate = []; // array che conterrà le notifiche non confermate
    if (id_residente !== undefined) {
        await notifiche.getNotifiche(token).then((res) => { // prende le notifiche
            for (let notifica of res.data) { // per ogni notifica
                if (notifica.id_residente === id_residente && notifica.confermata === false) { // se la notifica è del residente e non è confermata
                    notificheNonConfermate.push(notifica); // aggiunge la notifica all'array
                }
            }
        });
    //props.loading(false); // disattiva il caricamento
    } else {
        await notifiche.getNotifiche(token).then((res) => { // prende le notifiche
            for (let notifica of res.data) { // per ogni notifica
                console.log(id_stanza);
                if (notifica.id_stanza === id_stanza && notifica.confermata === false && notifica.id_residente === undefined) { // se la notifica è del residente e non è confermata
                    notificheNonConfermate.push(notifica); // aggiunge la notifica all'array
                }
            }
        });
    //props.loading(false); // disattiva il caricamento
    }
    return notificheNonConfermate; // ritorna l'array

}
    return (






        // <div key={"top" + props.stanza.id_stanza} id={props.banner ? "GrigliaAlarm" : "Griglia"} >
        //
        //     { console.log("test") }
        //     { console.log(props.stanza) }
        //
        //
        // </div>






        <>
            {props.visualizzazione === "griglia" ?
                <div
                id={props.stanza.sensori.some((item) => item.banner !== 0) ? "GrigliaAlarm" : "Griglia"}
                style={{ background: "#FFFFFF", cursor: props.stanza.sensori.some((item) => item.banner !== 0) ? "pointer" : "default" }}
                onClick={ props.stanza.sensori.some((item) => item.banner !== 0) ? async () => props.openPopup("notifiche", await getNotificheNonConfermate(accountData.token, undefined, props.stanza.id_stanza)) : null}
                >

                    { console.log(props.stanza) }

                    <table style={{ marginTop: "3%"  }}>

                        { console.log(t(props.stanza.tipo) == "Ingresso Principale") }

                        <tbody>

                        {/*<td style={{  textAlign: "center", color: "black", fontWeight: "800", fontSize:  "32px", fontFamily: 'Titillium Web' }} width="100%">*/}

                        <td style={{  textAlign: "center", color: "black", fontWeight: "800", fontSize: t(props.stanza.tipo) == "Ingresso Principale" ? "30px" : "32px", fontFamily: 'Titillium Web' }} width="100%">

                        {props.stanza.sensori.some((item) => item.banner !== 0) ? (
                            <div className="lamp-effect" style={{ display: "flex", justifyContent: "right", position:"absolute", top: "3%", right:"3%" }}>
                                <img src={Alert} style={{ width: "35px", height: "35px", alignSelf: "flex-end", cursor: "default" }} />
                            </div>
                            ) : (
                            <div style={{ width: "40px" }}></div>
                        )}

                        {/*{t(props.stanza.tipo)}*/}

                        {t(props.stanza.stanza)}

                        </td>
                        </tbody>
                    </table>
                    { console.log(getimgState(props.stanza)) }
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop:
                        // t(props.stanza.tipo) == "Ingresso Principale" ? "0px" : "20px",

                    t(props.stanza.tipo) == "Ingresso Principale" ||  t(props.stanza.stanza).startsWith("Entrata")  ? "0px" : "20px",

                    width: "100%", height: "60%" }}>
                    <img src={getimgState(props.stanza)} style={{ width: "100px", height: "100px", cursor: props.banner ? "pointer" : "default"}} />
                </div>
                <table style={{marginTop: t(props.stanza.tipo) == "Ingresso Principale" ? "0px" : "3%", height:"20px"}}>
                    <tbody>
                    <td style={{  textAlign: "center", color: "black", fontWeight: "800", fontSize: "15px", fontFamily: 'Titillium Web' }} width="100%">


                        {/*shhsshdhdsh*/}
                    {/*{props.stanza.stanza}*/}
                    </td>
                    </tbody>
                </table>
                </div>
                :
                <table
                    id="Lista"
                    onClick={() => props.openPopup("ambiente", props.stanza)}
                    style={{cursor: "pointer"}}
                >
                    <tbody>
                        <tr>
                            <td width="30%" >
                            {props.stanza.percorso}
                            </td>
                            <td width="%">
                                <div className="alarm" style={{ backgroundColor: "#FEF9E2" }}>
                                    &emsp;{props.stanza.presenza.stato}
                                    <div className="right">
                                        <img src={alarmY} alt="alrm" />
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            }
        </>
    );
}

export default Ambiente;