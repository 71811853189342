import React, {useRef, useState, useEffect } from 'react';
import Draggable from 'react-draggable';
import { Client } from 'paho-mqtt';

//import notificationSound from '../../components/twrong-answer-129254.mp3';

import notificationSound from '../../components/sound_of_windows_errore.mp3';


import arrowPurple from '../../imgs/icons/arrowPurple.svg';

import busybed from "../../imgs/icons/Busybed.svg";
import falldetected from "../../imgs/icons/falldetected.svg";
import emptyroom from "../../imgs/icons/emmptyroom.svg";
import offlinebedroom from "../../imgs/icons/offlinebedroom.svg";
import busybathroom from "../../imgs/icons/busybathroom.svg";
import emptybathroom from "../../imgs/icons/emptybathroom.svg";
import offlinebathroom from "../../imgs/icons/offlinebathroom.svg";

import StanzaComp from '../../components/Stanza.js';
import AmbienteComp from '../../components/Ambiente.js';
import PopupComp from '../../components/Popup.js';
import dashboardNode from 'node/dashboard/dashboard.js';
import strutturaNode from 'node/struttura/struttura.js';

import lang from "../../lang.json";
import Nucleo from '../../components/Nucleo.js';
const langSet = lang[localStorage.getItem("lang")].dashboard;
const audio = new Audio(notificationSound); 
//let stanze = [107, 114];
export default function Index(props) {
    const [accountData, setAccountData] = useState(JSON.parse(localStorage.getItem('account')))
    ;
    const [infosPopup, setInfosPopup] = useState(['']);
    const [dragPosition, setDragPosition] = useState(null);
    const [dashboard, setDashboard] = useState(null);
    const [dashboardroom, setDashboardroom] = useState(null);
    const [newDashboard, setnewDashboard] = useState([]);
    const [sort, setSort] = useState('residente');
    const [order, setOrder] = useState('asc');
    const [residente, setResidente] = useState(null);
    const [stanze, setStanze] = useState([]);
    const [mqttclient, setMqtt] = useState();
    const [mqtthanlder, setMqtthanlder] = useState();
    const [connected, setconnected] = useState(false);
    const [residentiState, setResidentiState] = useState([]);
    const [pianiState, setPianiState] = useState([]);
    const [stanzeState, setStanzeState] = useState([]);
    const [visualizzazione, setVisualizzazione] = useState(localStorage.getItem('visualizzazione'));
    const [piani, setPiani] = useState([]);
    const [pianoSel, setPianoSel] = useState(useState(localStorage.getItem('pianoSel')));
   // const [showheader, setShowheader] = useState();

    function centraPopup() { dragPosition === null ? setDragPosition({ x: 0, y: 0 }) : setDragPosition(null); }
    function clearmqtt() {
        if (mqttclient !== null && mqttclient !== undefined && connected === true) {
            setconnected(false);
            mqttclient.unsubscribe(`#`);
            mqttclient.disconnect()
            setMqtt(null);

        }
    }
    const playSound = () => {
        // Replace with your sound file path
        audio.volume = 0.5
        audio.play();
      };


    function openPopup(category, data) {
        document.getElementById("Popup").style.display = "flex";
        setInfosPopup([{ category, data }]);
        setResidente(data);
    }

    useEffect(() => { //per catturare il cambio di struttura //non aggiorna, da aggiustare
        setAccountData(JSON.parse(localStorage.getItem('account')));
        clearmqtt();
        //playAudio();
    }, [localStorage.getItem('account')]);

    function closePopup() {
        document.getElementById("Popup").style.display = "none";
    }

    

    useEffect(() => {
        props.loading(true);
        console.log(accountData.token);
        setTimeout(() => {
            getDash();
            props.loading(false);
        }, 500);
    }, [localStorage.getItem('struttura')]);

    async function getDash() {
        props.loading(true);
        await dashboardNode.getDashboard(accountData.token, sort, order).then(async (res) => {
            if (!res.result) throw res.error;
            setDashboard(res.data);
            
            const dash = await covertDashboard(res.data);
            console.log(dash);
            setnewDashboard(dash);
            
        }).catch((e) => openPopup('alarm', e.toString())).finally(() => {
            props.loading(false);
        });
        await dashboardNode.getDashboardRoom(accountData.token, sort, order).then(async (res) => {
            setDashboardroom(res.data);
            
            await getAmbienti(res.data);

        }).catch((e) => openPopup('alarm', e.toString())).finally(() => {
            props.loading(false);
        });
        await strutturaNode.getStrutturaAll(accountData.token).then(async (res) => {
            //array di piani
            let piani = [];
            //array di stanze
            let stanze = [];
            for (let key of res.data) {
               piani.push(key);
            }
            //console.log(piani);
            setPiani(piani);
        }).catch((e) => openPopup('alarm', e.toString())).finally(() => {
            props.loading(false);
        });

    }



    async function covertDashboard(data) {
        //per ogni residente controlla la stanza, quindi creo un array di stanze dove metto i residenti per stanza
        let info = [];
        try {
            //console.log(data);
            for (let key of data) {
                const id_stanza = "stanza" + key.id_stanza.toString();
                if (info[id_stanza] === undefined) {
                    
                    info[id_stanza] = [];
                    info[id_stanza].push(key);
                } else {
                    info[id_stanza].push(key);
                }
            //conto i letti e li assengo alla stanza
                let letti = 0;
                info[id_stanza].map((res) => {
                    if (res.residente !== null) {
                        letti++;
                    }
                });
                info[id_stanza].letti = letti;
            }
            // rimuovo le stanze vuote e le metto in un array
            let finaldata = [];
            for (let key in info) {
                if (info[key].letti !== 0) {
                    finaldata.push(info[key]);
                }
            }


            console.log(info);
            return finaldata;
        }
        catch (e) {
            console.log(e);
        }
    }

    async function getAmbienti(data) {
        props.loading(true);
        let info = [];
        //console.log(data);
        try {
            for (let key of data) {
                if (key.id_residenti.length === 0) {
                    
                    if (key.sensori !== null && key.sensori !== undefined) {
                        //aggiungilo a setStanze
                        key.sensori.map((sensore) => {
                            if (sensore.banner !== 0) {
                                key.allarme = true;

                            }
                        });
                    info.push({ id_stanza: key.id_stanza, sensori: key.sensori, percorso: key.percorso, numeroStanza: key.id_stanza, tipo: key.tipo, alarm: key.allarme, piano: key.piano, stanza: key.stanza });
                    }
                }
            }
            setStanze(info);
        } catch (e) {
            console.log(e);
        }
        // per orgni dashboard controlla se ci sono sensori_ambiente

        props.loading(false);
    }

    useEffect(() => {
        if (newDashboard !== null && newDashboard !== undefined) {
            console.log(newDashboard);
            let prova = []
            newDashboard.map((stanza) => {
                let allarme = false;
                stanza.map((res) => {
                    if (res.residente !== null) {
                        
                        res.sensori.map((sensore) => {
                            if (sensore.banner !== 0) {
                                allarme = true;
                            }
                        });
                        if (!Array.isArray(res.sensori_ambiente)) {
                            res.sensori_ambiente = [res.sensori_ambiente];
                        } else if (res.sensori_ambiente === undefined || res.sensori_ambiente === null) {
                            res.sensori_ambiente = [];
                        }
                        res.allarme = allarme;
                    }
                });
                stanza = {data: stanza , allarme};
                prova.push(stanza);

            });
            //console.log(prova);
            setStanzeState(prova);

            if (piani !== null && piani !== undefined) {
                piani.map((piano) => {
                    const sensoriPiano = [];
                    prova.map((stanza) => {
                        console.log(stanza)
                        
                        stanza.data.map((res) => {
                            if (res.piano === piano.id) {
                                //se qualche sensore ha allarme metti allarme del piano a true
                                res.sensori.map((sensore) => {
                                sensoriPiano.push(sensore);
                                });
                                res.sensori_ambiente.map((sensore) => {
                                    if (sensore !== undefined && sensore !== null) {
                                    sensoriPiano.push(sensore);
                                    }
                                });
                            }

                        });
                    });
                    stanze.map((stanza) => {
                        console.log(stanza);
                        if (stanza.piano === piano.id) {
                            stanza.sensori.map((sensore) => {
                                if (sensore !== undefined && sensore !== null) {
                                 sensoriPiano.push(sensore);
                                }
                            });
                        }
                    });
                    piano.sensori = sensoriPiano;
                });

            }
            console.log(piani);
            setPianiState(piani);

        }
        StreamData();
    }, [newDashboard, dashboard, connected, piani]);

    useEffect(() => {
        if (residente !== null) {
            let residenteX = residentiState.find((res) => res.id === residente.id);
            setInfosPopup([{ category: 'residente', data: residenteX }]);
        }
    }, [residentiState]);

    async function StreamData() {
        try {
            let prova = JSON.parse(localStorage.getItem('account'));
            let client = null;
            if(process.env.REACT_APP_NODE == "production") {
                client = new Client("dashboard.care4u.it", 443, "web_" + parseInt(Math.random() * 100, 10));
            } else {
                client = new Client("test-dashboard.care4u.it", 443, "web_" + parseInt(Math.random() * 100, 10));
            }
            if (connected !== true) {
            client.connect({
                password: prova.mqtt_password,
                userName: prova.db,
                useSSL: true,
                onSuccess: () => {
                    console.log("CONNECTED TO MQTT" + accountData.db) ;
                    setconnected(true);
                    // Now that we are connected, subscribe to topics here
                    client.subscribe(`${accountData.db}/dashboard/#`, {
                    onSuccess: () => {
                        console.log("Subscribed to all topics using 'dashboard' wildcard");
                    },
                    onFailure: (e) => {
                        console.log("Subscription failed", e);
                    },
                    });
                },
            });
            
            client.onConnectionLost = (responseObject) => {
                console.log("CONNECTION LOST - " + responseObject.errorMessage);
                //setconnected(false);
                clearmqtt();
                window.location.reload();
            };
            
            //window.location.reload();
            client.onMessageArrived = async (message) => {
                try {
                    console.log(message.destinationName, JSON.parse(message.payloadString.toString()));
                    let xtopic = message.destinationName.split("/");
                    //se il messaggio ha il banner a 2 allora faccio il suono
                    if (JSON.parse(message.payloadString.toString()).banner === 2) {
                        playSound();
                    }
                    setStanzeState(prevState => {
                        return prevState.map((stanza) => {
                            
                            return {
                                ...stanza,
                                data: stanza.data.map((res) => {
                                    if (res.id_residente === xtopic[3]) {
                                        
                                        return {
                                            ...res,
                                            sensori: res.sensori.map((sensore) => {
                                                if (sensore.id === xtopic[4]) {
                                                    
                                                    
                                                    return {
                                                        ...sensore,
                                                        stato: JSON.parse(message.payloadString.toString()).stato,
                                                        banner: JSON.parse(message.payloadString.toString()).banner,
                                                    }
                                                } else {
                                                    return sensore;
                                                } 
                                            }),
                                           allarme: res.sensori.some((sensore) => sensore.banner !== 0) ? true : false,
                                        }
                                        
                                    }
                                    else if (res.id_stanza === xtopic[3]) {
                                        res.sensori_ambiente.map((sensore) => {
                                            
                                            if (sensore.id === xtopic[4]) {
                                                
                                                sensore.stato = JSON.parse(message.payloadString.toString()).stato;
                                                sensore.banner = JSON.parse(message.payloadString.toString()).banner;
                                            }
                                        }
                                        );
                                        res.allarme = JSON.parse(message.payloadString.toString()).banner !== 0 ? true : false;
                                    } else {
                                        return res;
                                    }
                                    return res;
                                
                                }),
                                allarme: stanza.data.some((res) => res.allarme === true) ?  true : false,
                                letti: stanza.data.length,

                            }
                        }
                        );
                        
                        
                    });
                    setStanze(prevState => {
                        return prevState.map((stanza) => {
                            if (stanza.id_stanza === xtopic[3]) {
                                stanza.sensori.map((sensore) => {
                                    if (sensore.id === xtopic[4]) {
                                        sensore.stato = JSON.parse(message.payloadString.toString()).stato;
                                        sensore.banner = JSON.parse(message.payloadString.toString()).banner;
                                    }
                                }
                                );
                                stanza.allarme = JSON.parse(message.payloadString.toString()).banner !== 0 ? true : false;
                            }
                            return stanza;
                        }
                        );

                    });
                    setPianiState(prevState => {
                        return prevState.map((piano) => {
                            return {
                                ...piano,
                                sensori: piano.sensori.map((sensore) => {
                                    if (piano.id === xtopic[2]) {
                                        if (sensore.id === xtopic[4]) {
                                            sensore.stato = JSON.parse(message.payloadString.toString()).stato;
                                            sensore.banner = JSON.parse(message.payloadString.toString()).banner;
                                        }
                                    }
                                    return sensore;

                                }),

                            }
                        
                        }
                        );

                    }
                    );
                    

                }catch (e) {
                    console.log(e);
                }

            }

            //console.log(residentiState);
            setMqtt(client);
            setMqtthanlder();
            }
        } catch (e) {
            console.log(e);
        }
    }
    function getNumeroStanza (stanza) {
        let numeroStanza = 0;
        stanza.data.map((res) => {
            numeroStanza = res.id_stanza;
        }
        );
        return numeroStanza;
    }


    function SchedaInScheda(category, info, operation, data, previousPage) {
        setInfosPopup([{ category, info, operation, data, previousPage }]);
    }

    return (
        <>
            <div id="loading" />
            <div id="Popup">
                {/*<div onClick={() => centraPopup()} id="options">*/}
                {/*    {dragPosition === null ? "CENTRA POPUP" : "LIBERA POPUP"}*/}
                {/*</div>*/}
                <Draggable handle="#handle" position={dragPosition}>
                    <div className="popupContent popupcenter">
                        <PopupComp info={infosPopup} closePopup={closePopup} SchedaInScheda={SchedaInScheda} loading={props.loading} getDash={getDash} />
                    </div>
                </Draggable>
            </div>
            <div style={{ position: "absolute", width: "100%", left:"0px", marginTop: "10px"}} id="Header">
                {<div className='headerSX'>
                   {/**back arrow per settare la visualizzazione a "Nucleo" */}
                    {visualizzazione !== "Nucleo" ?
                    <> <div className="selettoreRound" onClick={() => {setVisualizzazione("Nucleo"); localStorage.setItem('visualizzazione', "Nucleo")}} key="back">
                        <img style={{ transform: "rotate(90deg)" }} src={
                            arrowPurple
                        } alt='back' id="back" />

                    </div>
                    <div style={{marginLeft:"10px", display: "flex", width: "200px"}}>
                        {pianoSel}
                        </div>
                    {/*<p>{visualizzazione}</p>}*/}
                    </>
                     : <></>}

                    {props.filtro === 'lista' ? <input type="search" name="search" autoComplete="off" onChange={(e) => props.cerca(e.target.value)} placeholder={langSet.cerca} /> : <></>}
                    
                </div>}
            <div className='headerCX' style={{ display: "flex", justifyContent: "center" }}>
            
            </div>
                <div className='headerDX'></div>
            </div>
           
            {visualizzazione === "Nucleo" ? <div id="ContentPageHead" style={{display:"none"}} > 
            
            </div>
             :
            <> </>
            }
            

            <div id="ContentPageLow" style={{marginTop: "0px"}}>
                <>
                    {visualizzazione === "Nucleo" ?
                        <>
                            {pianiState.sort((a, b) => {
                                /* DA CAMBIARE */
                                if (a.descrizione < b.descrizione) {
                                    return -1;
                                }
                                if (a.descrizione > b.descrizione) {
                                    return 1;
                                }
                                return 0;

                            }).map((piano, index) => (
                                <div className="piano" key={index}onClick={() => {setVisualizzazione(piano.id); localStorage.setItem('visualizzazione', piano.id); setPianoSel(piano.descrizione); localStorage.setItem('pianoSel', piano.descrizione)}}>
                                    <Nucleo piano={piano} openPopup={openPopup}  />
                                </div>
                            ))}
                        </> :
                        <>
                            {stanzeState.filter((stanza) => {
                                //se il piano è uguale a visualizzazione allora lo mostro
                                return stanza.data.some((res) => res.piano === visualizzazione);

                            }).sort((a, b) => {
                                // Convert id_stanza values to numbers for comparison

                                return a.data[0].id_stanza - b.data[0].id_stanza;

                            }).map((stanza, index) => (
                                <StanzaComp
                                    stanza={stanza}
                                    key={index}
                                    numeroStanza={getNumeroStanza(stanza)}
                                    visualizzazione={props.filtro}
                                    openPopup={openPopup}
                                    loading={props.loading}
                                    banner={ stanza.data.some((res) => res.sensori.some((sensore) => sensore !== undefined && sensore.banner !== 0)) && !stanza.data.some((res) => res.sensori_ambiente.some((sensore) => sensore !== undefined && sensore.banner !== 0))}
                                    letti={stanza.data.length}
                                />
                            ))}

                            {stanze.filter((stanza) => {
                                return stanza.piano === visualizzazione
                            }).map((stanza, index) => (
                                <AmbienteComp
                                    stanza={stanza}
                                    key={index}
                                    visualizzazione={props.filtro}
                                    openPopup={openPopup}
                                />
                             ))}
                        </>
                    }
                </>
            </div>
        </>
    );
}